import axios from "axios";
require('dotenv').config();
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const user_data = JSON.parse(localStorage.getItem('user_data'));
const authToken = user_data?.data?.token;
const company_id = user_data?.data?.company_id;
const requestHeader = {headers:{
  'Content-Type': 'application/json',
  'Authorization': authToken
}};

export const getPrivileges = async () => {
  try {
    const response = await axios.get(baseUrl + "/roles/capabilities/list", requestHeader);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getRoles = async (page:number, rowsPerPage:number, search:string) => {
  try {
    let searchQuery = '';
    if(search){
      searchQuery = `&search=${search}`
    }
    const response = await axios.get(`${baseUrl}/roles/list?company_id=${company_id}&page=${page}&per_page=${rowsPerPage}${searchQuery}`, requestHeader);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getRoleById = async (id: any) => {
  try {
    const response = await axios.get(baseUrl + "/roles/" + id, requestHeader);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createRole = async (formData: any) => {
  const returnData = {
    data: null,
    status: false,
    msg: "",
  };
  try {
    const response = await axios.post(baseUrl + "/roles/create", formData, requestHeader);
    if (response.data && response.data.status === 'success') {
      returnData.data = response.data;
      returnData.status = response.data.status;
      returnData.msg = response.data.message;
    } else {
      returnData.msg = response.data.msg;
    }
    return returnData;
  } catch (error) {
    return returnData;
  }
};

export const updateRole = async (formData: {},id: any) => {
  const returnData = {
    data: null,
    status: false,
    message: "",
  };
  try {
    const response = await axios.put(baseUrl + "/roles/update/"+id, formData, requestHeader);
    if (response.data && response.data.status === 'success') {
      returnData.data = response.data;
      returnData.status = response.data.status;
      returnData.message = response.data.message;
    } else {
      returnData.message = response.data.msg;
    }
    return returnData;
  } catch (error) {
    return returnData;
  }
};

export const deleteRoles = async (id: any) => {
  try {
    const response = await axios.delete(baseUrl + "/roles/delete/"+ id, requestHeader);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPermissions = async (userId: any) => {
  try {
    const response = await axios.get(`${baseUrl}/users/privileges?user_id=${userId}`, requestHeader);
    //If trail company and have time period over, redirect on login page.
    if (response.data.status === 'success') {
      let finalData = response.data.data;
      if(finalData?.is_trail){
          let trail_period_date = finalData?.trail_period;
          let dateTwo = trail_period_date;
          let date = new Date(); //Year, Month, Date 
          let dateOne = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' +
          ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + date.getFullYear();
          if (dateOne > dateTwo) { 
            localStorage.removeItem('user_data');
            window.location.href = `/login`;
          }
      }
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const subscription_status = async (userId: any) => {
  try {
    const response = await axios.get(`${baseUrl}/users/subscription_status?user_id=${userId}`, requestHeader);
    return response.data;
  } catch (error) {
    return error;
  }
};