import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { renderRoutes, privateRenderRoutes } from "./config/routes";
import PrivateRoute from './PrivateRoute'
import { CheckPrivileges } from './PermissionContexts.js';
import "./common.scss";

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    !loading && (
      <div className="App">
        <Suspense fallback={<div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}>
        </div>
        }>
          <BrowserRouter>
            <Switch>
              {renderRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <CheckPrivileges>
                {privateRenderRoutes.map(([key, route]) => (
                  <PrivateRoute
                    key={key}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </CheckPrivileges>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </div>
    )
  );
}

export default App;
